import React from 'react'
import Layout from 'components/layout'
import ScrollableContent from 'components/scrollable-content'
import ProductGrid from 'components/product-grid'
import Spacer from 'components/spacer'
import useLawnTractors from 'data/use-lawn-tractors'
import useResidentialZeroTurns from 'data/use-residential-zero-turns'
import useCommercialMowers from 'data/use-commercial-mowers'
import useCompactTractors from 'data/use-compact-tractors'
import useGators from 'data/use-gators'

const IndexPage = () => {
  return (
    <Layout>
      <ScrollableContent>
        <h2>Build your own John Deere equipment</h2>
        <p>Browse the product categories below to find the model you're looking for.</p>
        <LawnTractorsSection />
        <ResidentialZeroTurnsSection />
        <CommercialMowersSection />
        <CompactTractorsSection />
        <GatorsSection />
      </ScrollableContent>
    </Layout>
  )
}

const LawnTractorsSection = () => {
  const lawnTractorsData = useLawnTractors()

  if (lawnTractorsData.length === 0) {
    return null
  }

  return (
    <React.Fragment>
      <Spacer size='l' />
      <h3>Lawn Tractors</h3>
      <Spacer size='s' />
      <ProductGrid items={lawnTractorsData} />
    </React.Fragment>
  )
}

const ResidentialZeroTurnsSection = () => {
  const residentialZeroTurnsData = useResidentialZeroTurns()

  if (residentialZeroTurnsData.length === 0) {
    return null
  }

  return (
    <React.Fragment>
      <Spacer size='l' />
      <h3>Residential Zero-Turns</h3>
      <Spacer size='s' />
      <ProductGrid items={residentialZeroTurnsData} />
    </React.Fragment>
  )
}

const CommercialMowersSection = () => {
  const commercialMowersData = useCommercialMowers()

  if (commercialMowersData.length === 0) {
    return null
  }

  return (
    <React.Fragment>
      <Spacer size='l' />
      <h3>Commercial Mowers</h3>
      <Spacer size='s' />
      <ProductGrid items={commercialMowersData} />
    </React.Fragment>
  )
}

const CompactTractorsSection = () => {
  const compactTractorsData = useCompactTractors()

  if (compactTractorsData.length === 0) {
    return null
  }

  return (
    <React.Fragment>
      <Spacer size='l' />
      <h3>Compact Utility Tractors</h3>
      <Spacer size='s' />
      <ProductGrid items={compactTractorsData} />
    </React.Fragment>
  )
}

const GatorsSection = () => {
  const gatorsData = useGators()

  if (gatorsData.length === 0) {
    return null
  }

  return (
    <React.Fragment>
      <Spacer size='l' />
      <h3>Gator Utility Vehicles</h3>
      <Spacer size='s' />
      <ProductGrid items={gatorsData} />
    </React.Fragment>
  )
}

export default IndexPage
