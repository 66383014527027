import { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import isNumber from 'utils/is-number'

const baseData = [
  {
    handle: 'mid-size-crossover-gators',
    link: '/gators/mid-size-crossover-gators/',
    imageId: 'midSizeImage',
    title: 'Mid-Size Crossover Series',
    imageAlt: 'John Deere Mid-Size Crossover Series Gators',
    highlights: [
      'Up to 500-lb cargo capacity',
      '2 or 4 passenger',
      '4WD with independent suspension',
    ],
  },
  {
    handle: 'full-size-crossover-gators',
    link: '/gators/full-size-crossover-gators/',
    imageId: 'fullSizeImage',
    title: 'Full-Size Crossover Series',
    imageAlt: 'John Deere Full-Size Crossover Series Gators',
    highlights: [
      'Up to 2,000-lb towing capacity',
      '3 or 4 passenger',
      'Optional cab with heat and A/C',
    ],
  },
  {
    handle: 'work-series-gators',
    link: '/gators/work-series-gators/',
    imageId: 'workSeriesImage',
    title: 'Work Series',
    imageAlt: 'John Deere Work Series Gators',
    highlights: [
      'Up to 1,000-lb cargo capacity',
      'Gas, diesel, and electric options',
      'Available in 2WD, 4WD, and 6-wheel design',
    ],
  },
]

const useGators = () => {
  const data = useStaticQuery(query)

  const [dataMapped] = useState(() => {
    return baseData
      .map(item => {
        item.imageFluid = data[item.imageId].childImageSharp.fluid
        const seriesModels = data.allGators.nodes.filter(obj => obj.series.handle === item.handle)
        seriesModels.forEach(node => {
          node.options.forEach(option => {
            const minPrice = Math.min(
              ...[option.advertisedPrice, option.price].filter(val => isNumber(val) && val !== 0)
            )
            if (!item.startingAtPrice || minPrice < item.startingAtPrice) {
              item.startingAtPrice = minPrice
            }
          })
        })
        return item
      })
      .filter(item => isNumber(item.startingAtPrice))
  })
  return dataMapped
}

const query = graphql`
  {
    workSeriesImage: file(relativePath: { eq: "work-series-gators.jpg" }) {
      ...ProductGridImageSharp
    }
    midSizeImage: file(relativePath: { eq: "mid-size-crossover-gators.jpg" }) {
      ...ProductGridImageSharp
    }
    fullSizeImage: file(relativePath: { eq: "full-size-crossover-gators.jpg" }) {
      ...ProductGridImageSharp
    }
    allGators: allSanityJohnDeereEquipment(
      sort: { fields: options___price, order: ASC }
      filter: {
        subcategory: { handle: { eq: "gators" } }
        published: { eq: true }
        configureEnabled: { eq: true }
      }
    ) {
      nodes {
        options {
          price
        }
        series {
          handle
        }
      }
    }
  }
`

export default useGators
