import { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import isNumber from 'utils/is-number'

const baseData = [
  {
    handle: 'z300-series',
    link: '/residential-zero-turns/z300-series/',
    imageId: 'z300SeriesImage',
    title: 'Z300 Series',
    imageAlt: 'John Deere Z300 Series Residential Zero-Turns',
    highlights: [
      '20-25 HP',
      '42-, 48-, and 54-in. mower decks',
      '7 mph max forward speeds',
      'Ideal for up to 4 acres',
    ],
  },
  {
    handle: 'z500-series',
    link: '/residential-zero-turns/z500-series/',
    imageId: 'z500SeriesImage',
    title: 'Z500 Series',
    imageAlt: 'John Deere Z500 Series Residential Zero-Turns',
    highlights: [
      '24-25 HP',
      '48-, 54-, and 60-in. mower decks',
      '8-9 mph max forward speeds',
      'Ideal for up to 8 acres',
    ],
  },
  {
    handle: 'z700-series',
    link: '/residential-zero-turns/z700-series/',
    imageId: 'z700SeriesImage',
    title: 'Z700 Series',
    imageAlt: 'John Deere Z700 Series Residential Zero-Turns',
    highlights: [
      '23-25 HP',
      '48-, 54-, and 60-in. mower decks',
      '8-10 mph max forward speeds',
      'Ideal for 8+ acres',
    ],
  },
]

const useResidentialZeroTurns = () => {
  const data = useStaticQuery(query)

  const [dataMapped] = useState(() => {
    return baseData
      .map(item => {
        item.imageFluid = data[item.imageId].childImageSharp.fluid
        const seriesModels = data.allResidentialZeroTurns.nodes.filter(
          obj => obj.series.handle === item.handle
        )
        seriesModels.forEach(node => {
          node.options.forEach(option => {
            const minPrice = Math.min(
              ...[option.advertisedPrice, option.price].filter(val => isNumber(val) && val !== 0)
            )
            if (!item.startingAtPrice || minPrice < item.startingAtPrice) {
              item.startingAtPrice = minPrice
            }
          })
        })
        return item
      })
      .filter(item => isNumber(item.startingAtPrice))
  })
  return dataMapped
}

const query = graphql`
  {
    z300SeriesImage: file(relativePath: { eq: "z300-series.jpg" }) {
      ...ProductGridImageSharp
    }
    z500SeriesImage: file(relativePath: { eq: "z500-series.jpg" }) {
      ...ProductGridImageSharp
    }
    z700SeriesImage: file(relativePath: { eq: "z700-series.jpg" }) {
      ...ProductGridImageSharp
    }
    allResidentialZeroTurns: allSanityJohnDeereEquipment(
      sort: { fields: options___price, order: ASC }
      filter: {
        subcategory: { handle: { eq: "residential-zero-turns" } }
        published: { eq: true }
        configureEnabled: { eq: true }
      }
    ) {
      nodes {
        options {
          price
        }
        series {
          handle
        }
      }
    }
  }
`

export default useResidentialZeroTurns
