import { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import isNumber from 'utils/is-number'

const baseData = [
  {
    handle: '100-series',
    link: '/lawn-tractors/100-series/',
    imageId: 'oneHundredSeriesImage',
    title: '100 Series',
    imageAlt: 'John Deere 100 Series Lawn tractors',
    highlights: ['17.5-24 HP', '42-, 48-, and 54-in. mower decks', 'Ideal for up to 3 acres'],
  },
  {
    handle: '200-series',
    link: '/lawn-tractors/200-series/',
    imageId: 'twoHundredSeriesImage',
    title: '200 Series',
    imageAlt: 'John Deere 200 Series Lawn tractors',
    highlights: ['21.5-22 HP', '42- and 48-in. mower decks', 'Ideal for up to 3 acres'],
  },
  {
    handle: 'x300-series',
    link: '/lawn-tractors/x300-series/',
    imageId: 'x300SeriesImage',
    title: 'X300 Series',
    imageAlt: 'John Deere X300 Series Lawn tractors',
    highlights: ['21.5-23 HP', '42-, 48-, and 54-in. mower decks', 'Ideal for up to 4 acres'],
  },
  {
    handle: 'x500-series',
    link: '/lawn-tractors/x500-series/',
    imageId: 'x500SeriesImage',
    title: 'X500 Series',
    imageAlt: 'John Deere X500 Series Lawn tractors',
    highlights: ['24-25.5 HP', '48- and 54-in. mower decks', 'Ideal for up to 8 acres'],
  },
  {
    handle: 'x700-series',
    link: '/lawn-tractors/x700-series/',
    imageId: 'x700SeriesImage',
    title: 'X700 Series',
    imageAlt: 'John Deere X700 Series Lawn tractors',
    highlights: ['24-25.5 HP', '48-, 54-, and 60-in. mower decks', 'Ideal for 8+ acres'],
  },
]

const useLawnTractors = () => {
  const data = useStaticQuery(query)

  const [dataMapped] = useState(() => {
    return baseData
      .map(item => {
        item.imageFluid = data[item.imageId].childImageSharp.fluid
        const seriesModels = data.allLawnTractors.nodes.filter(
          obj => obj.series.handle === item.handle
        )
        seriesModels.forEach(node => {
          node.options.forEach(option => {
            const minPrice = Math.min(
              ...[option.advertisedPrice, option.price].filter(val => isNumber(val) && val !== 0)
            )
            if (!item.startingAtPrice || minPrice < item.startingAtPrice) {
              item.startingAtPrice = minPrice
            }
          })
        })
        return item
      })
      .filter(item => isNumber(item.startingAtPrice))
  })
  return dataMapped
}

const query = graphql`
  {
    oneHundredSeriesImage: file(relativePath: { eq: "100-series.jpg" }) {
      ...ProductGridImageSharp
    }
    twoHundredSeriesImage: file(relativePath: { eq: "200-series.jpg" }) {
      ...ProductGridImageSharp
    }
    x300SeriesImage: file(relativePath: { eq: "x300-series.jpg" }) {
      ...ProductGridImageSharp
    }
    x500SeriesImage: file(relativePath: { eq: "x500-series.jpg" }) {
      ...ProductGridImageSharp
    }
    x700SeriesImage: file(relativePath: { eq: "x700-series.jpg" }) {
      ...ProductGridImageSharp
    }
    allLawnTractors: allSanityJohnDeereEquipment(
      sort: { fields: options___price, order: ASC }
      filter: {
        subcategory: { handle: { eq: "lawn-tractors" } }
        published: { eq: true }
        configureEnabled: { eq: true }
      }
    ) {
      nodes {
        options {
          price
        }
        series {
          handle
        }
      }
    }
  }
`

export default useLawnTractors
