import { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import isNumber from 'utils/is-number'

const baseData = [
  {
    handle: 'z900-series',
    link: '/commercial-mowers/z900-series/',
    imageId: 'z900SeriesImage',
    title: 'Z900 Series',
    imageAlt: 'John Deere Z900 Series Commercial Zero-Turns',
    highlights: [
      '25-37.4 HP',
      '48-, 54-, 60-, and 72-in. mower decks',
      'Gas, EFI, and diesel options',
    ],
  },
]

const useCommercialMowers = () => {
  const data = useStaticQuery(query)

  const [dataMapped] = useState(() => {
    return baseData
      .map(item => {
        item.imageFluid = data[item.imageId].childImageSharp.fluid
        const seriesModels = data.allCommercialMowers.nodes.filter(
          obj => obj.series.handle === item.handle
        )
        seriesModels.forEach(node => {
          node.options.forEach(option => {
            const minPrice = Math.min(
              ...[option.advertisedPrice, option.price].filter(val => isNumber(val) && val !== 0)
            )
            if (!item.startingAtPrice || minPrice < item.startingAtPrice) {
              item.startingAtPrice = minPrice
            }
          })
        })
        return item
      })
      .filter(item => isNumber(item.startingAtPrice))
  })
  return dataMapped
}

const query = graphql`
  {
    z900SeriesImage: file(relativePath: { eq: "z900-series.jpg" }) {
      ...ProductGridImageSharp
    }
    allCommercialMowers: allSanityJohnDeereEquipment(
      sort: { fields: options___price, order: ASC }
      filter: {
        subcategory: { handle: { eq: "commercial-mowers" } }
        published: { eq: true }
        configureEnabled: { eq: true }
      }
    ) {
      nodes {
        options {
          price
        }
        series {
          handle
        }
      }
    }
  }
`

export default useCommercialMowers
