import { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import isNumber from 'utils/is-number'

const baseData = [
  {
    handle: '1-family',
    link: '/compact-tractors/1-family/',
    imageId: 'oneSeriesImage',
    title: '1 Series',
    imageAlt: 'John Deere 1 Series Compact Tractors',
    highlights: [
      '23-25 HP',
      'Hydrostatic transmission',
      'Loader lift capacity up to 754 lbs.',
      'Drive-over mower decks up to 60-in.',
    ],
  },
  {
    handle: '2-family',
    link: '/compact-tractors/2-family/',
    imageId: 'twoSeriesImage',
    title: '2 Series',
    imageAlt: 'John Deere 2 Series Compact Tractors',
    highlights: [
      '25-38 HP',
      'Hydrostatic transmission',
      'Loader lift capacity up to 1,120 lbs.',
      'Drive-over mower decks up to 72-in.',
    ],
  },
  {
    handle: '3-family',
    link: '/compact-tractors/3-family/',
    imageId: 'threeSeriesImage',
    title: '3 Series',
    imageAlt: 'John Deere 3 Series Compact Tractors',
    highlights: [
      '25-46 HP',
      'Hydrostatic or gear transmission',
      'Loader lift capacity up to 1,122 lbs.',
      'Drive-over mower decks up to 72-in. on select models',
    ],
  },
  {
    handle: '4-family',
    link: '/compact-tractors/4-family/',
    imageId: 'fourSeriesImage',
    title: '4 Series',
    imageAlt: 'John Deere 4 Series Compact Tractors',
    highlights: [
      '44-75 HP',
      'Hydrostatic or PowerReverser™ transmission',
      'Loader lift capacity up to 2,222 lbs.',
      'Pull-behind rotary cutters up to 84-in.',
    ],
  },
]

const useCompactTractors = () => {
  const data = useStaticQuery(query)

  const [dataMapped] = useState(() => {
    return baseData
      .map(item => {
        item.imageFluid = data[item.imageId].childImageSharp.fluid
        const seriesModels = data.allCompactTractors.nodes.filter(
          obj => obj.series.handle === item.handle
        )
        seriesModels.forEach(node => {
          node.options.forEach(option => {
            const minPrice = Math.min(
              ...[option.advertisedPrice, option.price].filter(val => isNumber(val) && val !== 0)
            )
            if (!item.startingAtPrice || minPrice < item.startingAtPrice) {
              item.startingAtPrice = minPrice
            }
          })
        })
        return item
      })
      .filter(item => isNumber(item.startingAtPrice))
  })

  return dataMapped
}

const query = graphql`
  {
    oneSeriesImage: file(relativePath: { eq: "1-family.jpg" }) {
      ...ProductGridImageSharp
    }
    twoSeriesImage: file(relativePath: { eq: "2-family.jpg" }) {
      ...ProductGridImageSharp
    }
    threeSeriesImage: file(relativePath: { eq: "3-family.jpg" }) {
      ...ProductGridImageSharp
    }
    fourSeriesImage: file(relativePath: { eq: "4-family.jpg" }) {
      ...ProductGridImageSharp
    }
    allCompactTractors: allSanityJohnDeereEquipment(
      sort: { fields: options___price, order: ASC }
      filter: {
        subcategory: { handle: { eq: "compact-tractors" } }
        published: { eq: true }
        configureEnabled: { eq: true }
      }
    ) {
      nodes {
        options {
          price
        }
        series {
          handle
        }
      }
    }
  }
`

export default useCompactTractors
